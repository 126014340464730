import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import IMTFieldVisitForm from "./BaseIdentificationForm/IMTFieldVisitForm";
import {useEffect, useState} from "react";

const BaseIdentificationForm = ({visit}) => {
    const [isUpdate, setIsUpdate] = useState(Number(visit.getPatient().getValue("height")));
    const [weight, setWeight] = useState(Number(visit.getPatient().getValue("weight")));
    const [bmi, setBMI] = useState(visit.getValue("bmi"));
    const [bmi_color_hex, setBmiColorHex] = useState(visit.getValue("bmi_color_hex"));
    const [bmi_txt, setBmiTxt] = useState(visit.getValue("bmi_txt"));


    useEffect(() => {

        const fetchValue = async () => {
            setBMI(await visit.getValue("bmi"));
            setBmiColorHex(await visit.getValue("bmi_color_hex"));
            setBmiTxt(await visit.getValue("bmi_txt"));
        }

        fetchValue();

    }, [visit, isUpdate]);

    const handleUpdate = (value) => {
        setIsUpdate(!isUpdate);

    };


    return <FieldSetVisitForm title={"Основное"} id={"base"}>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="height" typeFieldForm={"range"} label={"Рост"} min={"100"} max={"230"} units={"см."} onChange={handleUpdate}  />
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="weight" typeFieldForm={"range"} label={"Вес"} min={"30"} max={"250"} units={"кг."} step={"0.1"} onChange={handleUpdate} />
        <IMTFieldVisitForm bmi={bmi} bmi_color_hex={bmi_color_hex} bmi_txt={bmi_txt}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="telephone" typeFieldForm={"text"} label={"Контактный номер телефона"}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="home_address" typeFieldForm={"text"} label={"Домашний адрес"}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="doctor_fio" typeFieldForm={"text"} label={"Лечащий врач"} fetchUrl={"https://ra-api.medsystem.online/doctor_list"}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="medical_organization_of_attachment" typeFieldForm={"text"} label={"Медицинская организация прикрепления"}/>

    </FieldSetVisitForm>
}

export default BaseIdentificationForm;