
const IMTFieldVisitForm = ({bmi, bmi_txt, bmi_color_hex}) => {

  //  height = height / 100;
  //  const imt = height && weight ? Math.round((weight/(height*height)) * 10)/10 : 0;

    const ColumnIMTValue = ({name, value, color}) => {
        return <div className={"py-3 px-4 flex gap-2.5 text-base items-center justify-center"}
                    style={{
                        backgroundColor: color
                    }}>
            <span>{name}</span>
            <strong >{value}</strong>
        </div>
    }

    const InfoIMT = ({value}) => {
        let info = "";
        if (value <= 15.9) {
            info = "выраженный дефицит массы тела";
        } else if (value <= 18.5) {
            info = "недостаточная масса тела";
        } else if (value <= 25) {
            info = "нормальная масса тела";
        } else if (value <= 30) {
            info = "избыточная масса тела";
        } else if (value <= 35) {
            info = "ожирение 1 степени";
        } else if (value <= 39.9) {
            info = "ожирение 2 степени";
        } else if (value <= 1000) {
            info = "ожирение 3 степени";
        }

        return <div className={"text-base uppercase w-96"}>{bmi_txt} </div>
    }

    function getColorHEX(value) {
        let color = "";
        if (value <= 15.9) {
            color = "#0000ff";
        } else if (value <= 18.5) {
            color = "#00ffff";
        } else if (value <= 25) {
            color = "#00ff00";
        } else if (value <= 30) {
            color = "#80ff00";
        } else if (value <= 35) {
            color = "#ffff00";
        } else if (value <= 39.9) {
            color = "#ff8000";
        } else if (value <= 1000) {
            color = "#ff0000";
        }
        return color;
    }
    //const color = getColorHEX(imt);

    return <div className={"w-full"}>
        <div className={"bg-lemon-pa flex items-center justify-center py-7 gap-7"}
             style={{
                 backgroundColor: bmi_color_hex
             }}>
            <div className={"text-2xl font-medium text-black"}>ИМТ</div>
            <div className={"rounded-full font-medium bg-white block w-10 h-10 flex items-center justify-center"}
                >{bmi}</div>
            <InfoIMT value={bmi} />
        </div>
        <div className={"bg-white grid grid-cols-6  "}>
            <ColumnIMTValue name={"Недост."} value={"16-18,5"} color={"#00ffff"}/>
            <ColumnIMTValue name={"Норм."} value={"18,5 - 25"} color={"#00ff00"}/>
            <ColumnIMTValue name={"Изб."} value={"25 - 30"} color={"#80ff00"}/>
            <ColumnIMTValue name={"Ож. 1 ст."} value={"30 - 35"} color={"#ffff00"}/>
            <ColumnIMTValue name={"Ож. 2 ст."} value={"35-39.9"} color={"#ff8000"}/>
            <ColumnIMTValue name={"Ож. 3 ст."} value={">40"} color={"#ff0000"}/>
        </div>
    </div>
}

export default IMTFieldVisitForm;