import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import {useState} from "react";
import FracturesTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/FracturesTreatmentVisitForm";
import AnemiaTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/AnemiaTreatmentVisitForm";
import OncologyTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/OncologyTreatmentVisitForm";
import IHDTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/IHDTreatmentVisitForm";
import DiabetTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/DiabetTreatmentVisitForm";
import ONMKTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/ONMKTreatmentVisitForm";
import TuberculosisTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/TuberculosisTreatmentVisitForm";
import PregnancyTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/PregnancyTreatmentVisitForm";
import IZLTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/IZLTreatmentVisitForm";
import ArterialHypertensionTreatmentVisitForm
    from "./SoputstvuyshieTreatmentForm/ArterialHypertensionTreatmentVisitForm";
import NonAlcoholicTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/NonAlcoholicTreatmentVisitForm";
import HepatitisBTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/HepatitisBTreatmentVisitForm";
import HepatitisCTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/HepatitisCTreatmentVisitForm";
import SecondarySystemicAmyloidosisTreatmentVisitForm
    from "./SoputstvuyshieTreatmentForm/SecondarySystemicAmyloidosisTreatmentVisitForm";
import SecondaryOsteoarthritisTreatmentVisitForm
    from "./SoputstvuyshieTreatmentForm/SecondaryOsteoarthritisTreatmentVisitForm";
import TunnelSyndromesTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/TunnelSyndromesTreatmentVisitForm";
import SubluxationTreatmentVisitForm from "./SoputstvuyshieTreatmentForm/SubluxationTreatmentVisitForm";
import OtherAssociatedConditionsTreatmentVisitForm
    from "./SoputstvuyshieTreatmentForm/OtherAssociatedConditionsTreatmentVisitForm";

const SoputstvuyshieTreatmentForm = ({visit}) => {
    const [isOncology, setIsOncology] = useState(visit.getValue("is_oncology"));
    const [isChd, setIsChd] = useState(visit.getValue("is_chd"));
    const [isDiabetesMellitus, setIsDiabetesMellitus] = useState(visit.getValue("is_diabetes_mellitus"));
    const [isTuberculosis, setIsTuberculosis] = useState(visit.getValue("is_tuberculosis"));
    const [isAci, setIsAci] = useState(visit.getValue("is_aci"));

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];

    const yearOptions = [{name: "-", value: ""}];
    const year = new Date().getFullYear();
    for(let i = year; i > 1900; i-- ) {
        yearOptions.push({name: i, value: i});
    }

    const handleIsOncologyChange = (value) => {
        setIsOncology(value);
    };
    const handleIsChdChange = (value) => {
        setIsChd(value);
    };
    const handleIsDiabetesMellitusChange = (value) => {
        setIsDiabetesMellitus(value);
    };
    const handleIsTuberculosisChange = (value) => {
        setIsTuberculosis(value);
    };
    const handleIsAciChange = (value) => {
        setIsAci(value);
    };

    return <FieldSetVisitForm title={"Сопутствующее состояние/заболевания"} id={"base"}>
        <AnemiaTreatmentVisitForm visit={visit}/>
        <OncologyTreatmentVisitForm visit={visit}/>
        <IHDTreatmentVisitForm visit={visit}/>
        <DiabetTreatmentVisitForm visit={visit}/>
        <ONMKTreatmentVisitForm visit={visit}/>
        <TuberculosisTreatmentVisitForm visit={visit}/>
        <PregnancyTreatmentVisitForm visit={visit}/>
        <IZLTreatmentVisitForm visit={visit}/>
        <ArterialHypertensionTreatmentVisitForm visit={visit}/>
        <NonAlcoholicTreatmentVisitForm visit={visit}/>
        <HepatitisBTreatmentVisitForm visit={visit}/>
        <HepatitisCTreatmentVisitForm visit={visit}/>
        <SecondarySystemicAmyloidosisTreatmentVisitForm visit={visit}/>
        <SecondaryOsteoarthritisTreatmentVisitForm visit={visit}/>
        <TunnelSyndromesTreatmentVisitForm visit={visit}/>
        <SubluxationTreatmentVisitForm visit={visit}/>
        <OtherAssociatedConditionsTreatmentVisitForm visit={visit}/>

        {/*
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="related_conditions" typeFieldForm={"checkbox"} label={"Другие сопутствующие состояния/заболевания"} hasButtonNewItem={true}/>
        <EditFieldVisitForm visit={visit} objectType={"visit"} fieldObject="complications" typeFieldForm={"checkbox"} label={"Осложнения"} hasButtonNewItem={true}/>
        */}
        <FracturesTreatmentVisitForm visit={visit}/>

    </FieldSetVisitForm>
}

export default SoputstvuyshieTreatmentForm;